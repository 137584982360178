<template>
     <ChangeCatalityc/>
</template>

<script>
import ChangeCatalityc from '@/components/app/ChangeCatalityc'
export default {
    components:{
        ChangeCatalityc
    }
}
</script>

<style scoped>
@media screen and (max-width: 960px){
	#cards {
		margin-bottom: 20px;
	}
}
#cards {
		margin-top: 100px;
	}
.uk-overlay-default {
    background: rgba(255,255,255,.3) !important;
}
</style>